<template>
  <div>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              v-if="canEditActivity"
              variant="primary"
              :disabled="isAddRecordActive"
              :to="{ name: 'task-configuration'}"
            >
              <span> {{ $t('ADD_TASK') }} </span>
              <feather-icon icon="PlusCircleIcon" />
            </b-button>
          </b-col>
          <!-- Header text-->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-start mt-1 mb-md-0">
              <small
                v-if="content.id"
                class="text-muted"
              >
                <b-badge
                  pill
                  variant="light-primary"
                  class="mr-1 text-capitalize small"
                >
                  {{ content.contentType.name }}
                </b-badge>
                <b-badge
                  pill
                  variant="light-warning"
                  class="mr-1 text-capitalize small"
                >
                  {{ content.contentFrequency.name }}
                </b-badge>
                <b-badge
                  pill
                  variant="light-info"
                  class="mr-1 text-capitalize small"
                >
                  {{ content.contentStatus.name }}
                </b-badge>
                <b-badge
                  pill
                  variant="light-secondary"
                  class="mr-1 text-capitalize small"
                >
                  {{ formatDateTime(content.start) }}
                </b-badge>
                <b-badge
                  pill
                  variant="light-secondary"
                  class="mr-1 text-capitalize small"
                >
                  {{ formatDateTime(content.end) }}
                </b-badge>
              </small>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card>
      <div class="mb-1 d-flex align-items-center">
        <span>{{ $t('TASK_SUBTASK') }}</span>
      </div>
      <app-timeline
        v-for="(contentTask,index) in allContentTasks"
        :id="contentTask.id.toString()"
        :key="contentTask.id"
      >
        <!-- Sub task-->
        <app-timeline-item
          :id="contentTask.id.toString()"
          :key="index"
          variant="primary"
          icon="FileTextIcon"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6> <b-avatar
              size="30"
              :text="contentTask.sequence.toString()"
              variant="light-primary"
            />{{ contentTask.name }} {{
              contentTask.points !== null && contentTask.points !== 0
                ? '(' + contentTask.points + ')'
                : ''
            }}
            </h6>
            <div class="text-nowrap">
              <feather-icon
                v-if="content.contentTypeId === contentType.SURVEY && allContentTasks.length > 0 && allContentTasks[index].subtasks && allContentTasks[index].subtasks.length > 0"
                icon="EyeIcon"
                size="16"
                class="cursor-pointer ml-1"
                @click="openTaskModel(index)"
              />
              <feather-icon
                v-if="canEditActivity"
                :id="`game-row-${contentTask.id}-send-icon`"
                icon="EditIcon"
                class="cursor-pointer mx-1"
                size="16"
                @click="$router.push({ name: 'task-configuration-id', params: { id: contentTask.id }})"
              />
              <feather-icon
                v-if="canEditActivity"
                :id="`game-row-${contentTask.id}-send-icon`"
                icon="PlusIcon"
                class="cursor-pointer mr-1"
                size="16"
                @click="navigateSubActivityConfiguration(contentTask.id,0)"
              />
              <feather-icon
                v-if="canEditActivity"
                :id="`game-row-${contentTask.id}-send-icon`"
                icon="Trash2Icon"
                class="cursor-pointer mr-1"
                size="16"
                @click="confirmTaskDeletion(contentTask)"
              />
            </div>
          </div>
          <p v-html="contentTask.description" />
          <b-collapse
            :id="contentTask.id.toString()"
            visible
          >
            <b-list-group
              flush
              class="mt-1"
            >
              <b-list-group-item
                v-for="(subtask,i) in contentTask.subtasks"
                :id="subtask.id.toString()"
                :key="i"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span> <b-avatar
                         size="30"
                         :text="subtask.sequence.toString()"
                         variant="light-warning"
                       />{{ subtask.name }}
                  {{
                    subtask.points !== null && subtask.points !== 0
                      ? '(' + subtask.points + ')'
                      : ''
                  }} </span>
                <!-- Dropdown -->
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      v-if="canEditActivity"
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="navigateSubActivityConfiguration(contentTask.id,subtask.id)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ $t('BUTTON.EDIT') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="confirmSubTaskDeletion(subtask)">
                    <feather-icon icon="Trash2Icon" />
                    <span class="align-middle ml-50">{{ $t('BUTTON.DELETE') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
        </app-timeline-item>
      </app-timeline>
    </b-card>
    <task-view-model
      :all-content-tasks="allContentTasks"
      :index="taskIndex"
    />
  </div>
</template>

<script>

import {
  BListGroup,
  BListGroupItem,
  VBToggle,
  VBTooltip,
  BCard,
  BCollapse,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { mapActions, mapState } from 'vuex'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import { isContentEditableOrDeletable, updateBreadcrumb } from '@/constants/utils'
import useJwt from '@/auth/jwt/useJwt'
import { mixinDate } from '@/constants/mixinDate'
import constants from '@/constants/static.json'
import useActivityList from './useActivityList'
import TaskViewModel from '../task-configuration/TaskViewModel.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    AppTimeline,
    AppTimelineItem,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    TaskViewModel,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  mixins: [mixinDate],
  data() {
    return {
      content: useJwt.getContent() || {},
      userData: useJwt.getUser() || {},
      canEditActivity: true,
      contentType: constants.CONTENT_TYPE,
      breadCrumb: constants.BREADCRUMB,
      taskIndex: -1,
    }
  },
  setup() {
    const {
      blankTask,
      blankCompetitionTask,
      blankSubTask,
    } = useActivityList()

    const {
      successMessage,
      errorMessage,
    } = mixinAlert()

    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const subTask = ref(JSON.parse(JSON.stringify(blankSubTask)))
    const competitionTask = ref(JSON.parse(JSON.stringify(blankCompetitionTask)))
    const isHabitHandlerSidebarActive = ref(false)
    const isBehaviourHandlerSidebarActive = ref(false)
    const isAddRecordActive = ref(true)
    return {
      competitionTask,
      isHabitHandlerSidebarActive,
      isBehaviourHandlerSidebarActive,
      isAddRecordActive,
      task,
      subTask,
      blankTask,
      blankSubTask,
      successMessage,
      errorMessage,
      updateBreadcrumb,
    }
  },
  computed: {
    ...mapState('task', ['allContentTasks']),
  },
  async created() {
    try {
      const routeId = parseInt(router.currentRoute.params.id, 10)
      if (routeId) {
        const response = await this.fetchContentByID(routeId)
        if (response && response.data) {
          this.content = response.data
          this.fetchContentTaskByID(this.content.id)
          this.canEditActivity = isContentEditableOrDeletable(this.content, this.userData.userRoleId)
          this.configureBreadcrumbs()
        }
      }
    } catch (error) {
      this.errorMessage(this.$i18n.t('MESSAGE.PAGE_LOAD_FAILED'))
    }
  },
  methods: {
    ...mapActions('task', [
      'fetchContentTaskByID',
      'deleteTask',
      'deleteSubTask']),
    ...mapActions('content', ['fetchContentByID']),
    confirmTaskDeletion(taskData) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${taskData.name}  ${this.$i18n.t('TASK')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.handleTaskDeletion(taskData)
          }
        })
    },
    configureBreadcrumbs() {
      const company = useJwt.getBreadcrumb(this.breadCrumb.COMPANY)
      const project = useJwt.getBreadcrumb(this.breadCrumb.PROJECT)
      const content = useJwt.getBreadcrumb(this.breadCrumb.CONTENT)
      const breadcrumbs = [
        { text: company.name, to: `/companies/company-view/${company.id}`, active: false },
        { text: project.name, to: `/project/project-view/${project.id}`, active: false },
        { text: content.name, to: `/content/content-view/${content.id}`, active: false },
        { text: this.$i18n.t('TASK_SUBTASK'), active: true },
      ]
      updateBreadcrumb(this, breadcrumbs)
    },
    openTaskModel(index) {
      this.taskIndex = index
      this.$bvModal.show('task-view-modal')
    },
    navigateSubActivityConfiguration(taskID, subTaskID) {
      const id = taskID
      const subtaskId = subTaskID
      this.$router.push({
        name: 'sub-task-configuration-id',
        params: { id },
        query: { subtaskId },
      })
    },
    navigateMediaConfiguration(competitionID, tId, sId) {
      const id = competitionID
      const taskID = tId
      const subTaskID = sId
      this.$router.push({
        name: 'media-list-id',
        params: { id },
        query: { taskID, subTaskID, objectID: 'game' },
      })
    },
    handleTaskDeletion(data) {
      this.deleteTask(data.id).then(response => {
        if (response.data) {
          this.successMessage(this.$i18n.t('MESSAGE.TASK_DELETED'))
          this.fetchContentTaskByID(data.contentId)
        }
      }).catch(() => {
        this.errorMessage(this.$i18n.t('MESSAGE.PERFORMED_TASK_EXIST'))
      })
    },
    handleSubTaskDeletion(data) {
      this.deleteSubTask(data.id).then(response => {
        if (response.data) {
          this.successMessage(this.$i18n.t('MESSAGE.SUBTASK_DELETED'))
          this.fetchContentTaskByID(this.content.id)
        }
      }).catch(() => {
        this.errorMessage(this.$i18n.t('MESSAGE.PERFORMED_SUBTASK_EXIST'))
      })
    },
    confirmSubTaskDeletion(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.name}  ${this.$i18n.t('SUBTASK')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.handleSubTaskDeletion(data)
          }
        })
    },
  },
}
</script>
