export default function useActivityList() {
  const blankTask = {
    taskID: null,
    subTask: [],
    taskdescription: '',
    taskbeskrivning: '',
    points: '',
    taskhelp: '',
    startValue: null,
    endValue: null,
    requireComment: 0,
    requireMultiplier: 0,
    question: null,
  }

  const blankSubTask = {
    subtaskID: null,
    subtaskstring: '',
    subtaskpoint: 0,
    subtaskHelpstring: null,
    question: '',
    startValue: 0,
    endValue: 0,
    radiotag: '',
    mediaID: 0,
    sortorder: 0,
  }
  const blankCompetitionTask = {
    comp_id: 0,
    sortOrder: 0,
  }

  return {
    blankTask,
    blankSubTask,
    blankCompetitionTask,
  }
}
